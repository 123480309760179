<template>
	<div>
		<p class="m-right-title">课程列表</p>
		<div class="m-list" v-loading="loading">
			<div class="m-item" v-for="(item,index) in list">
				<div class="m-item-img">
					<div class="m-item-tip">{{item.type == 1 ? '视频' :'文档'}}</div>
					<img :src="item.cover" />
				</div>
				<div class="m-item-con u-m-l-16">
					<p class="u-line-2 m-item-font1">{{item.name}}</p>
					<div class="u-flex">
						<div class="m-item-time">
							<img src="../../assets/img/common/icon-time4.png" />
							<span class="u-m-l-6">学时安排：{{item.period}}</span>
						</div>
						<div class="m-item-jindu u-m-l-30">
							<span>学习进度：</span>
						</div>
							<el-progress style="width: 200px;" :percentage="item.schedule" :stroke-width="10"></el-progress>
					</div>
				</div>
				<div class="u-flex-col u-flex-1 u-col-bottom">
					<div class="m-item-btn"  @click="to_course(item)">去学习</div>
					<!-- <template v-if="item.status == 2"> -->
						<div class="m-item-btn m-item-btn-extend u-m-t-8" @click="start_practice(item)">答题练习</div>
						<div class="m-item-btn m-item-btn-extend u-m-t-8" @click="to_link('/center/case',{course_id:item.id})">案例题练习</div>
						<div class="m-item-btn m-item-btn-extend u-m-t-8" @click="to_link('/center/test2',{paper_id:item.paper_id})">考试</div>
					<!-- </template> -->
				</div>
			</div>
		</div>
		<div class="u-flex u-row-center u-m-b-60">
			<el-pagination
			  background
			  layout="prev, pager, next"
			   @current-change="currentChange"
			  :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {to_link} from "@/router/api"
	import {getUserCourseList} from "@/api/center.js"
	export default {
		data(){
			return {
				loading:false,
				params:{
					page:1,
					pageNum:9,
					cate_id:'',
				},
				list:[],
				total:0,
			}
		},
		mounted() {
			console.log(this.$route);
			this.params.cate_id = this.$route.params.typeId
			this.getList()
		},
		methods:{
			to_link,
			start_practice(item){
				if(item.is_cate == 0){
					to_link('/center/test',{course_id:item.id,cate_id:item.cate_id})
				}else{
					to_link('/center/testCate',{course_id:item.id,cate_id:item.cate_id})
				}
			},
			currentChange(e){
				this.params.page = 1
				this.getList()
			},
			//课程详情
			to_course(item){
				if(item.type == 1){
					to_link('/video',{id:item.id})
				}else{
					to_link('/files',{id:item.id})
				}
			},
			async getList(){
				this.loading = true
				let res = await getUserCourseList(this.params)
				setTimeout(()=>{
					this.loading = false
				},500)
				if(res.code == 200){
					this.list = res.data
					this.total = res.count ||0
				}
			}
		}
	}
</script>

<style lang="scss" scoped> 
	.m-right-title{
		display: flex;
		height: 64px;
		line-height: 20px;
		padding-top: 32px;
		font-size: 20px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #000000;
		padding-left: 38px;
		border-bottom: 1px solid #EEEEEE;
		box-sizing: border-box;
	}
	.m-list{
		display: flex;
		flex-direction: column;
		padding-top: 20px;
		padding-left: 38px;
		padding-right: 38px;
		padding-bottom: 60px;
		.m-item{
			display: flex;
			height: 90px;
			margin-right: 22px;
			box-sizing: content-box;
			padding: 22px 0;
			cursor: pointer;
			border-bottom: 1px dashed #D1D1D1;
			.m-item-font1{
				font-size: 14px;
				color: #505050;
				line-height: 22px;
			}
			.m-item-img{
				position: relative;
				width: 160px;
				height: 90px;
				display: flex;
				align-items: center;
				overflow: hidden;
				.m-item-tip{
					position: absolute;
					top: 11px;
					left: 0;
					z-index: 1;
					width: 40px;
					height: 20px;
					font-size: 14px;
					color: #fff;
					opacity: 0.5;
					background: #000;
					text-align: center;
					line-height: 20px;
				}
				img{
					width: 100%;
				}
			}
			.m-item-jindu{
				display: flex;
				color: #D6102A;
				font-size: 14px;
			}
			.m-item-con{
				display: flex;
				flex-direction: column;
				width: 480px;
				height: 90px;
				justify-content: space-between;
				
			}
			.m-item-time{
				display: flex;
				align-items: center;
				color: #7C7C7C;
				font-size: 14px;
				line-height: 14px;
			}
			.m-item-btn{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 117px;
				height: 35px;
				background: #F8A310;
				border-radius: 18px;
				font-size: 14px;
				color: #fff;
			}
			.m-item-btn-extend{
				background: #D6102A;
			}
		}
	}
</style>

<style lang="scss">
	.m-item-con{
		.el-progress-bar__inner{
			background-color: #D6102A;
		}
	}
</style>
